function ResourceManager(options) {
	var t = this;

	// exports
	t.fetchResources = fetchResources;
	t.fetchAllResources = fetchAllResources;
	t.updateResources = updateResources;
	t.generateBreakoutTooltips = generateBreakoutTooltips;

	// local
	var sources = []; // source array
	var cache; // cached resources

	_addResourceSources(options['resources']);

	function updateResources(
		resources,
		forceFetch,
		resetScroll,
		fromViewChange,
		forceNoFetch
	) {
		//Refresh our resource sources
		t.setForceFetch(forceFetch);
		t.setForceNoFetch(forceNoFetch);
		sources = [];
		_addResourceSources(resources);

		//Set the scroll position so we can restore it later
		t.setScrollPosition(t.getScrollPosition());
		//Render the view with new resource source list
		if (!fromViewChange) {
			t.render(null, resetScroll);
		}
	}
	/**
	 * ----------------------------------------------------------------
	 * Categorize and add the provided sources
	 * ----------------------------------------------------------------
	 */
	function _addResourceSources(_sources) {
		var source = {};

		if ($.isFunction(_sources)) {
			// is it a function?
			source = {
				resources: _sources,
			};
			sources.push(source);
		} else if (typeof _sources == 'string') {
			// is it a URL string?
			source = {
				url: _sources,
			};
			sources.push(source);
		} else if (typeof _sources == 'object') {
			// is it json object?
			for (var i = 0; i < _sources.length; i++) {
				var s = _sources[i];
				//normalizeSource(s); //This is currently conflicting with the event normalizer and I can't see why it is necessary right now.
				source = {
					resources: s,
				};
				sources.push(source);
			}
		}
	}

	/**
	 * ----------------------------------------------------------------
	 * Fetch resources from source array
	 * ----------------------------------------------------------------
	 */

	function fetchAllResources() {
		return options['resourcesAll']();
	}
	function fetchResources(useCache, currentView) {
		// if useCache is not defined, default to true
		useCache = typeof useCache !== 'undefined' ? useCache : true;

		if (cache != undefined && useCache) {
			// get from cache
			return cache;
		} else {
			// do a fetch resource from source, rebuild cache
			cache = [];
			var len = sources.length;
			for (var i = 0; i < len; i++) {
				var resources = _fetchResourceSource(sources[i], currentView);
				cache = cache.concat(resources);
			}
			return cache;
		}
	}

	/**
	 * ----------------------------------------------------------------
	 * Fetch resources from each source.  If source is a function, call
	 * the function and return the resource.  If source is a URL, get
	 * the data via synchronized ajax call.  If the source is an
	 * object, return it as is.
	 * ----------------------------------------------------------------
	 */
	function _fetchResourceSource(source, currentView) {
		var resources = source.resources;

		if (resources) {
			if ($.isFunction(resources)) {
				return resources();
			}
		} else {
			var url = source.url;
			if (url) {
				var data = {};
				if (typeof currentView == 'object') {
					var startParam = options.startParam;
					var endParam = options.endParam;
					if (startParam) {
						data[startParam] = Math.round(
							+currentView.visStart / 1000
						);
					}
					if (endParam) {
						data[endParam] = Math.round(+currentView.visEnd / 1000);
					}
				}

				$.ajax(
					$.extend({}, source, {
						data: data,
						dataType: 'json',
						cache: false,
						success: function (res) {
							res = res || [];
							resources = res;
						},
						error: function () {
							alert('ajax error getting json from ' + url);
						},
						async: false, // too much work coordinating callbacks so dumb it down
					})
				);
			}
		}
		return resources;
	}

	/**
	 * ----------------------------------------------------------------
	 * normalize the source object
	 * ----------------------------------------------------------------
	 */

	//Currently not using this as it seems to interfere with our object
	function normalizeSource(source) {
		if (source.className) {
			if (typeof source.className == 'string') {
				source.className = source.className.split(/\s+/);
			}
		} else {
			source.className = [];
		}
		var normalizers = fc.sourceNormalizers;
		for (var i = 0; i < normalizers.length; i++) {
			normalizers[i](source);
		}
	}

	function generateBreakoutTooltips(
		placement,
		containerElement,
		breakoutList,
		useShortNames,
		hide
	) {
		var tooltipElements;
		var tooltipElement;
		var breakoutPosition;
		var title;
		var template;
		var showTooltip = 'detailed';
		var showTooltip = options.breakoutTooltips;
		var nameOnly = showTooltip === 'name' && useShortNames;

		if (
			showTooltip === 'none' ||
			(showTooltip !== 'detailed' && !nameOnly) ||
			hide
		) {
			return;
		}

		tooltipElements = containerElement.find('[data-toggle="tooltip"]');

		template =
			'<div class="tooltip tooltip-breakout-detail" role="tooltip">' +
			'<div class="tooltip-arrow"></div>' +
			'<div class="tooltip-inner"></div>' +
			'</div>';

		for (var i = 0; i < tooltipElements.length; i++) {
			tooltipElement = tooltipElements[i];
			breakoutPosition = Number(tooltipElement.dataset.position);

			$(tooltipElement).tooltip({
				container: 'body',
				html: true,
				title: createTooltipHTML(
					breakoutList[breakoutPosition],
					nameOnly
				),
				template: template,
				animation: false,
				placement:
					nameOnly && placement === 'bottom' ? 'top' : placement,
				trigger: 'hover',
				delay: {
					show: 0,
					hide: 0,
				},
			});
		}

		function createTooltipHTML(breakout, nameOnly) {
			if (!breakout) {
				return '';
			}

			if (nameOnly) {
				return breakout.name ? breakout.name : '';
			}

			var template;
			var tagContent = '';

			var folderContent =
				breakout.folderName && !breakout.isFolder
					? '<h3>' + breakout.folderName + '</h3>'
					: '';

			var descriptionContent = breakout.description
				? '<p>' + breakout.description + '</p>'
				: '';

			if (breakout.tags) {
				tagContent +=
					'<div class="tag-container" style="margin-bottom: 10px;">';
				for (var i = 0; i < breakout.tags.length; i++) {
					tagContent +=
						'<span class="tag ' + breakout.tags[i].class + '">';
					tagContent +=
						'<span class="tag-content">' +
						breakout.tags[i].name +
						'</span>';
					tagContent += '</span>';
				}
				tagContent += '</div>';
			}

			template =
				'<div class="filter-settings">' +
				'<h2>' +
				'<span>' +
				breakout.name +
				'</span>' +
				'<span class="background-message">' +
				(breakout.shortName ? ' | ' + breakout.shortName : '') +
				'</span>' +
				'</h2>' +
				folderContent +
				descriptionContent +
				tagContent;

			return template;
		}
	}
}
